import {instance} from '../index';

export const giftsApi = {
  generateGift () {
    return instance.get('gifts/generate')
  },
  saveGift (data) {
    return instance.post('gifts', data)
  },
  getActiveGifts () {
    return instance.get('gifts?sort=createdon_desc&active=true');
  },
  updateGift (id, data) {
    return instance.patch(`/gifts/${id}`, data)
  },
}
