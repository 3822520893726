<script>

import './GiftsGenerator.styl'
import {mapGetters} from 'vuex';
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import Spinner from '@/components/common/Spinner/Spinner.vue';
import InfoPopup from '@/components/common/modals/InfoPopup.vue';
import {giftsApi} from '@/api/admin/gifts';
import PlaneCheckbox from '@/components/common/Checkbox/PlaneCheckbox.vue';

export default {
  name: 'gifts-generator',
  data: () => ({
    gift: '',
    selectedPlan: null,
    attributedTo: '',
    gifts: [],
    loading: false,
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    isOneTime: false,
  }),
  computed: {
    ...mapGetters([
      'getPeriods',
      'getPeriodById',
      'getEnrichedSubscriptionsPlans',
      'getActiveEnrichedSubscriptionsPlans',
    ]),
  },
  async mounted () {
    try {
      await this.$store.dispatch('fetchEnrichedSubscriptionsPlans')
      await this.$store.dispatch('fetchPeriods')
      await this.reloadGifts();
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    copy (text) {
      navigator.clipboard.writeText(text);
    },
    async disableGift (id) {
      const data = {
        gift: {
          isActive: false,
        },
      }

      await this.executeIntegration(async () => {
        try {
          const response = await giftsApi.updateGift(id, data);

          if (response.data !== null) {
            this.clearFields();
            await this.reloadGifts();
          } else {
            console.log(response);
            this.showInfoDialog('Something went wrong');
          }
        } catch (e) {
          console.log(e)
          this.showInfoDialog('Something went wrong');
        }
      })
    },
    getDisplayDate (dateString) {
      return dateString === null
        ? ''
        : `${new Date(dateString).toLocaleString('en-US')}`;
    },
    getDisplayValuePlan (item) {
      return item
        ? `${item.name} ${item.duration} ${item.period.name} - ${item.price} ${item.currency.code}`
        : null;
    },
    getDisplayValuePlanById (id) {
      const plan = this.getEnrichedSubscriptionsPlans.find(p => p.id === id);
      return this.getDisplayValuePlan(plan);
    },
    async generateGift () {
      await this.executeIntegration(async () => {
        try {
          const response = await giftsApi.generateGift();

          if (response.data !== null) {
            this.gift = response.data;
          } else {
            console.log(response);
            this.showInfoDialog('Something went wrong');
          }
        } catch (e) {
          console.log(e)
          this.showInfoDialog('Something went wrong');
        }
      });
    },
    async reloadGifts () {
      await this.executeIntegration(async () => {
        try {
          const giftData = await giftsApi.getActiveGifts();
          this.gifts = giftData.data?.gifts;
        } catch (e) {
          console.error(e)
        }
      })
    },
    async saveGift () {
      if (!this.validateFields()) {
        return
      }

      const data = {
        gift: {
          name: this.attributedTo,
          code: this.gift,
          subscriptionPlanId: this.selectedPlan.id,
          isOneTime: this.isOneTime,
        },
      }

      await this.executeIntegration(async () => {
        try {
          const response = await giftsApi.saveGift(data);

          if (response.data !== null) {
            this.clearFields();
            await this.reloadGifts();
          } else {
            console.log(response);
            this.showInfoDialog('Something went wrong');
          }
        } catch (e) {
          console.log(e)
          this.showInfoDialog('Something went wrong');
        }
      })
    },
    validateFields () {
      if (!this.gift) {
        this.showInfoDialog('Gift code not entered');
        return false;
      }
      if (!this.selectedPlan) {
        this.showInfoDialog('Plan not selected');
        return false;
      }
      return true;
    },
    showInfoDialog (title) {
      this.$modal.show('InfoPopup', { title: title });
    },
    async executeIntegration (callback) {
      this.loading = true;

      try {
        await callback()
      } finally {
        this.loading = false;
      }
    },
    clearFields () {
      this.gift = null;
      this.selectedPlan = null;
      this.attributedTo = null;
    },
  },
  components: {
    PlaneCheckbox,
    InfoPopup,
    Spinner,
    SvgIcon,
  },
}
</script>

<template>
  <div class="dashboard">
    <vue-scroll :ops="ops">
      <div id="title" class="title">Gift generator</div>
      <div id="plansContainer" style="margin-top: 25px">
        <div
          v-for="(item, index) in getActiveEnrichedSubscriptionsPlans"
          :key="item.id"
          class="sub-radio-group">
          <input
            :id="'price-input-'+index"
            v-model="selectedPlan"
            type="radio"
            name="plan"
            class="radio-input"
            :value="item"
          >
          <label class="radio-input-label" :for="'price-input-'+index">{{ getDisplayValuePlan(item) }}</label>
        </div>
      </div>
      <div id="isOneTimeCheckBox"
           class="oneTimeCheckbox"
           style="margin-left: 20px; margin-top: 25px;">
        <PlaneCheckbox
          :isChecked.sync="isOneTime"
          label="SINGLE USE"
        />
      </div>
      <div id="attributedToContainer" class="gift" style="margin-top: 25px">
        <input id="attributedToContainer"
               class="field"
               v-model="attributedTo"
               placeholder="Attributed to"
               type="text"/>
      </div>
      <div id="giftContainer" class="gift" style="margin-top: 25px">
        <input id="giftInput"
               class="field gift"
               v-model="gift"
               placeholder="Gift"
               type="text"/>
        <button id="copyButton"
                class="buttonCopy input"
                @click.prevent="copy(gift)">
              <span>
                  <SvgIcon name="copy"/>
              </span>
        </button>
      </div>
      <div id="buttonsContainer" class="gift" style="margin-top: 25px">
        <button id="generateButton"
                class="giftButton"
                style="margin-right: 10px"
                @click.prevent="generateGift">
          <span class="text">Generate</span>
        </button>
        <button id="saveButton"
                class="giftButton"
                style="margin: 0 10px"
                @click.prevent="saveGift">
          <span class="text">Save gift</span>
        </button>
      </div>
      <table id="giftTable"
             class="table-gift"
             style="margin-top: 25px">
        <thead>
        <div
          class="title"
             style="margin-bottom: 25px">Active gifts</div>
        <tr class="table-gift-header">
          <th class="table-gift-cell">Gift</th>
          <th class="table-gift-cell">Attributed to</th>
          <th class="table-gift-cell">Plan</th>
          <th class="table-gift-cell">Created date</th>
          <th class="table-gift-cell">Single use</th>
          <th class="table-gift-cell">Disable</th>
        </tr>
        </thead>
        <tbody class="table-gift-body">
        <tr v-for="(item, index) in gifts" :key="index" class="table-gift-row">
          <td class="table-gift-cell">
            <span class="mobile-label">Gift:</span>
            <button id="copyPromoButton" class="buttonCopy" @click.prevent="copy(item.code)">
                <span>
                  <SvgIcon name="copy"/>
                </span>
            </button>
            <span style="text-transform: none; font-weight: bolder" @click.prevent="copy(item.code)">{{ item.code }}</span>
          </td>
          <td class="table-gift-cell">
            <span class="mobile-label">Attributed to:</span>
            <span>{{ item.name }}</span>
          </td>
          <td class="table-gift-cell">
            <span class="mobile-label">Plan:</span>
            <span>{{ getDisplayValuePlanById(item.subscriptionPlanId) }}</span>
          </td>
          <td class="table-gift-cell">
            <span class="mobile-label">Created date:</span>
            <span>{{ getDisplayDate(item.createdOn) }}</span>
          </td>
          <td class="table-gift-cell">
            <span class="mobile-label">Single use:</span>
            <PlaneCheckbox :isChecked="item.isOneTime" :isDisabled="true"/>
          </td>
          <td class="table-gift-cell">
            <span class="mobile-label">Disable:</span>
            <button id="disableGiftButton" class="buttonDisable" @click.prevent="disableGift(item.id)">
                <span>
                  <SvgIcon name="close"/>
                </span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </vue-scroll>
    <div v-if="loading" class="spinner-overlay">
      <Spinner/>
    </div>
    <InfoPopup/>
  </div>
</template>
